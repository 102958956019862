define("discourse/plugins/discourse-translator/discourse/services/translator", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TranslatorService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "documentTitle", [_service.service]))();
    #documentTitle = (() => (dt7948.i(this, "documentTitle"), void 0))();
    async translatePost(post) {
      const response = await (0, _ajax.ajax)("/translator/translate", {
        type: "POST",
        data: {
          post_id: post.id
        }
      });
      post.detectedLang = response.detected_lang;
      post.translatedText = response.translation;
      post.translatedTitle = response.title_translation;
      if (this.siteSettings.experimental_inline_translation) {
        if (post.post_number === 1) {
          post.topic.set("fancy_title", response.title_translation);
          this.appEvents.trigger("header:update-topic", post.topic);
          this.documentTitle.setTitle(response.title_translation);
        }
        post.set("cooked", response.translation);
        post.set("can_translate", false);
        this.appEvents.trigger("post-stream:refresh", {
          id: post.id
        });
      }
    }
    clearPostTranslation(post) {
      post.detectedLang = null;
      post.translatedText = null;
      post.translatedTitle = null;
    }
  }
  _exports.default = TranslatorService;
});